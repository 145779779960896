import map from './Map';

const config = {
    path: {
        img: '/img/',
    },
    version: 'v0.0.1',
    company: {
        name: 'Sole Productions',
        lat: 55.879256,
        lng: -4.312116,
    },
    font: {
        head1: 'Raleway, Arial, sans-serif',
        body1: 'Lato, Arial, sans-serif',
    },
    google: {
        apiKey: process.env.REACT_APP_GOOGLE_API,
        analytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
        recaptcha: {
            publicKey: process.env.REACT_APP_GOOGLE_RECAPTCH,
        },
        map: map,
    },
    meta: {
        description:
            'Village View from AMA Homes offers modern luxury apartments in a prime city location, overlooking the picturesque Dean Village.',
        image: 'img/logo/meta/main.jpg',
        title: 'Village View - Edinburgh West End | AMA Homes',
        url: process.env.REACT_APP_URL_SITE,
    },
    url: {
        site: process.env.REACT_APP_URL_SITE,
        api: process.env.REACT_APP_URL_API,
        img: '/img/',
        //api: 'https://api.soleproductions.co.uk/',
        //img: 'https://www.soleproductions.co.uk/img/',
    },
};

if (process.env.NODE_ENV === 'development') {
    // config.google.apiKey = 'AIzaSyCJzzCI2jXQrIENxgv2PEXErdbJIlxdzHY';
    // config.google.analytics = '';
    // config.google.recaptcha.publicKey = '6LfPcuMSAAAAANNlpHenOty0vFfFI8PlvIR5agBX';
    // config.url.api = 'http://localhost/sole-productions-js/api/';
    // config.url.img = 'http://localhost:3010/img/';
}

export default config;
