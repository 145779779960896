import { CLEAR_ROUTE, MERGE_ROUTE, SET_ROUTE } from 'store/Types';
import * as R from 'ramda';

const initialState = {
    loading: true,

    meta: {
        title: 'Sole Productions : Videography & Photography : Scotland',
        description:
            'Sole Productions are a Glasgow based production company specialising in video production, promotional content, photography, camera operating and editing.',
    },
};

export default function RouteReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_ROUTE:
            return R.clone(initialState);
        case MERGE_ROUTE:
            return R.mergeDeepRight(state, action.payload);
        case SET_ROUTE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
