//ERROR
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_ERROR = 'SET_ERROR';

//PROJECT
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const MERGE_PROJECT = 'MERGE_PROJECT';
export const SET_PROJECT = 'SET_PROJECT';

//ROUTE
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const MERGE_ROUTE = 'MERGE_ROUTE';
export const SET_ROUTE = 'SET_ROUTE';
