import { combineReducers } from 'redux';

import ErrorReducer from './reducer/ErrorReducer';
import ProjectReducer from './reducer/ProjectReducer';
import RouteReducer from './reducer/RouteReducer';

export default combineReducers({
    error: ErrorReducer,
    project: ProjectReducer,
    route: RouteReducer,
});
