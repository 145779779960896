import { CLEAR_PROJECT, MERGE_PROJECT, SET_PROJECT } from 'store/Types';
import * as R from 'ramda';

const initialState = {
    items: [],
    item: {},
    loading: true,
    fetch: false,
    filter: '',
    attrs: {},
    page: 1,
    pages: 1,
    total: 0,
};

export default function ProjectReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PROJECT:
            return R.clone(initialState);
        case MERGE_PROJECT:
            return R.mergeDeepRight(state, action.payload);
        case SET_PROJECT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
