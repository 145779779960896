import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/Store';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Config from 'config/Config';

const App = React.lazy(() => import('./App'));
const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV !== 'development') {
    ReactGA.initialize(Config.google.analytics);
}

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Suspense fallback={<div className='loading' />}>
                    <App />
                </Suspense>
            </Router>
        </Provider>
    </React.StrictMode>
);
